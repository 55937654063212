import { Utils } from 'Shared/utils';
import { Button } from 'Shared/button-toggle';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('JIBSettingsFrameModule', function () {
   new JibStatusToggle();
});

export const JibStatusToggle = (window.JibStatusToggle = new Class({
   ajaxSpinner: new Element('img', {
      src: window.shared_constants.BaseURI('SITE_IMAGE_AJAX_LOAD'),
   }),

   initialize: function () {
      this.form = document.id('sidebarJibStatusToggle');
      this.guideid = this.form.getProperty('data-guideid');

      this.jibStatusToggle = new Button.Toggle('jibStatusToggle', {
         callback: this.toggle.bind(this),
      });
   },

   buildAjaxSpinner: function (elClass) {
      let element = new Element('span', {
         class: 'ajax-spinner ' + elClass,
      });

      return element.adopt(this.ajaxSpinner);
   },

   toggle: function (ev) {
      let el = ev.target,
         self = this,
         enabled = el.get('data-value') == 'enabled';

      $('jibStatusToggleField').adopt(self.buildAjaxSpinner('jibEnable'));

      new Request.AjaxIO('setJibEnabled', {
         onSuccess: function (response) {
            $$('.ajax-spinner').dispose();

            if (response.error) {
               let msg = Utils.createAlert('error', response.error);
               $('jibStatusToggleErrors').grab(msg, 'top');
               self.jibStatusToggle.untoggle();
               return;
            }

            $$('.form-builder').toggle(response.jibEnabled);
         },
      }).send(self.guideid, enabled);
   },
}));
